import React, { useContext, useEffect, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { ReactComponent as Logo } from "../../../assets/images/logo/new-logo.svg";
import { ReactComponent as User } from "../../../assets/images/icons/user-icon.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./header.scss";
import { api } from "api/Api";
import { LOGOUT_URL } from "constants/ApiUrls";
import Cookies from "js-cookie";
import AuthContext from "utils/context/AuthContext";
import UserContext from "utils/context/UserContext";
import {
  ADMIN,
  ApplyToWork,
  CLINICIAN,
  CLINICIAN_SIGNUP,
  FACILITY,
  FACILITY_SIGNUP,
  HireStaff,
  ROLE_CLINICIAN,
  ROLE_FACILITY,
  VERIFICATION_REQUIRED,
} from "constants/AppConstants";
import { isEmpty } from "radash";
import NotificationIcon from "../Notifications/NotificationIcon";

function LandingPageHeader(...args) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const toggle1 = () => setDropdownOpen((prevState) => !prevState);
  const toggle2 = () => setDropdownOpen1((prevState) => !prevState);
  const { isUserAuthenticated, setIsUserAuthenticated } =
    useContext(AuthContext);
  const { user, setUser } = useContext(UserContext);
  const HS = HireStaff(isUserAuthenticated);
  const AW = ApplyToWork(isUserAuthenticated);
  const role = Cookies.get("userRole");
  const handleClick = () => {
    navigate("/");
  };

  const handleLogout = () => {
    api(LOGOUT_URL, { id: user.id, roles: user.roles }).then((res) => {
      setUser({});
      setIsUserAuthenticated(false);
      Cookies.remove("purplePTAuthToken");
      Cookies.remove("userRole");
      Cookies.remove("userId");
      Cookies.remove("2FA");
      let id = localStorage.getItem('PURPTID')
      if (id) {
        clearInterval(id);
      }
      navigate("/");
    });
  };

  let redirectUrl = "";

  if (user && user?.roles) {
    if (user?.roles === FACILITY) {
      redirectUrl = `${FACILITY_SIGNUP}/?step=${(user?.facilityId?.signupStageCount || 0) + 1}`;
    }

    if (user?.roles === CLINICIAN || user?.clinicianId) {
      redirectUrl = `${CLINICIAN_SIGNUP}/?step=${user?.clinicianId?.signupStageCount + 1}`;

    }
  }

  return (
    <>
      <div className="landing-navbar">
        <div className="custom-container">
          <Navbar {...args} expand="md" className="pt-4">
            <span onClick={handleClick} className="cursor-pointer">
              <Logo />
            </span>

            <div className="d-flex align-items-center">
              <div className="nav-right-side d-block d-md-none">
                <NotificationIcon
                  className="me-3 d-none"
                  id="NotificationResponsive"
                />

                <Dropdown
                  isOpen={dropdownOpen1}
                  toggle={toggle2}
                  className="me-3 ms-0"
                >
                  <DropdownToggle className="p-0 user-icon">
                    user
                  </DropdownToggle>
                  <DropdownMenu id="header-menu">
                    {!isEmpty(user) && isUserAuthenticated && (
                      <>
                        {user?.roles === ADMIN ||
                          user?.facilityId?.isSignupCompleted ||
                          user?.clinicianId?.isSignupCompleted ? (
                          <DropdownItem
                            tag={Link}
                            to={
                              user?.roles === ADMIN
                                ? "/admin/settings"
                                : user.facilityId
                                  ? "/facility/settings"
                                  : "/clinician/settings"
                            }
                            className="login-logout dropdown-item p-0"
                          >
                            Account Settings
                          </DropdownItem>
                        ) : (
                          <DropdownItem
                            tag={Link}
                            to={redirectUrl}
                            className="login-logout dropdown-item p-0"
                          >
                            Complete Signup
                          </DropdownItem>
                        )}
                      </>
                    )}
                    {isUserAuthenticated ?
                      <DropdownItem
                      tag={"a"}
                        className="login-logout"
                        onClick={() => isUserAuthenticated && handleLogout()}
                      >
                        {"Logout"}
                      </DropdownItem>
                      :
                      <DropdownItem
                        tag={Link}
                        to="/login"
                        className="login-logout"
                      >
                        {"Login"}
                      </DropdownItem>

                    }
                  </DropdownMenu>
                </Dropdown>
              </div>
              <NavbarToggler onClick={toggle} />
            </div>

            <Collapse isOpen={isOpen} navbar>
              <Nav className="me-auto ms-auto" navbar>
                <button
                  onClick={toggle}
                  className="pt-btn-icon btn-primary d-md-none"
                >
                  X
                </button>
                <NavItem>
                  <NavLink
                    to="/clinician"
                    onClick={window.innerWidth < 767 && toggle}
                  >
                    For Clinicians
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/facility"
                    onClick={window.innerWidth < 767 && toggle}
                  >
                    For Facilities
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/contactus"
                    onClick={window.innerWidth < 767 && toggle}
                  >
                    Contact Us
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/faq"
                    onClick={window.innerWidth < 767 && toggle}
                  >
                    FAQ
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/aboutus"
                    onClick={window.innerWidth < 767 && toggle}
                  >
                    About Us
                  </NavLink>
                </NavItem>
                {!isUserAuthenticated &&
                <NavItem className={"login-nav"}>
                  <NavLink
                    to="/login"
                    onClick={window.innerWidth < 767 && toggle}
                    
                  >
                    Login
                  </NavLink>
                </NavItem>
                }
                {VERIFICATION_REQUIRED(user, toggle, isUserAuthenticated, redirectUrl, (user?.facilityId?.isSignupCompleted ||
                  user?.clinicianId?.isSignupCompleted))}
              </Nav>

              {isUserAuthenticated ? (
                <>
                  <>
                    {(user?.roles === "admin" || role === "subadmin") && (
                      <Nav className="me-auto ms-auto" navbar>
                        <NavItem>
                          <Link to="/admin/users">Users</Link>
                        </NavItem>
                        <NavItem>
                          <Link to="/admin/jobs">Job Posts</Link>
                        </NavItem>
                        <NavItem>
                          <Link to="/admin/shifts">Confirmed shifts</Link>
                        </NavItem>
                      </Nav>
                    )}
                  </>
                  {/* <>
                    {role === "subadmin" && (
                      <Nav className="me-auto ms-auto" navbar>
                        <NavItem>
                          <Link to="/admin/users">Users</Link>
                        </NavItem>
                      </Nav>
                    )}
                  </> */}
                  <>
                    {ROLE_CLINICIAN.includes(user?.roles) &&
                      user?.isApprovedByAdmin && (
                        <Nav className="me-auto ms-auto" navbar>
                          <NavItem>
                            <Link to="/clinician/jobboard">Job Board</Link>
                          </NavItem>
                          <NavItem>
                            <Link to="/clinician/dashboard">My Dashboard</Link>
                          </NavItem>
                          <NavItem>
                            <Link to="/chat-profile">Chat</Link>
                          </NavItem>
                        </Nav>
                      )}
                  </>
                  <>
                    {ROLE_FACILITY.includes(user?.roles) &&
                      user?.isApprovedByAdmin && (
                        <Nav className="me-auto ms-auto" navbar>
                          <NavItem>
                            <Link to="/facility/shiftmanagement">
                              Shift management
                            </Link>
                          </NavItem>
                          <NavItem>
                            <Link to="/chat-profile">Chat</Link>
                          </NavItem>
                        </Nav>
                      )}
                  </>
                </>
              ) : (
                <></>
              )}

              {/* I think it is used for showing in Desktop View -Mitul Mistry */}
              {isUserAuthenticated ? (
                <div className={"nav-right-side d-md-block" + " "}>
                  <NotificationIcon />

                  <Dropdown isOpen={dropdownOpen} toggle={toggle1}>
                    <DropdownToggle className="p-0 user-icon">
                      User
                    </DropdownToggle>

                    <DropdownMenu id="header-menu">
                      {!isEmpty(user) && isUserAuthenticated && (
                        <>
                          {user?.roles === ADMIN ||
                            user?.roles === "subadmin" ||
                            user?.facilityId?.isSignupCompleted ||
                            user?.clinicianId?.isSignupCompleted ? (
                            <DropdownItem
                              tag={Link}
                              to={
                                user?.roles === ADMIN
                                  ? "/admin/settings"
                                  : user?.roles === "subadmin"
                                    ? "/admin/settings"
                                    : user.facilityId
                                      ? "/facility/settings"
                                      : "/clinician/settings"
                              }
                              className="login-logout dropdown-item p-0"
                            >
                              Account Settings
                            </DropdownItem>
                          ) : (
                            <DropdownItem
                              tag={Link}
                              to={redirectUrl}
                              className="login-logout dropdown-item p-0"
                            >
                              Complete Signup
                            </DropdownItem>
                          )}
                        </>
                      )}
                      <DropdownItem
                        tag={"a"}
                        className="login-logout dropdown-item p-0 cursor-pointer"
                        onClick={() => handleLogout()}
                        id="logout"
                      >
                        Logout
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              ) : (
                <Dropdown isOpen={dropdownOpen} toggle={toggle1}>
                  <DropdownToggle className="p-0 user-icon">
                    User
                  </DropdownToggle>
                  <DropdownMenu id="header-menu">
                    {/* <Link to="/login" className="login-logout" id="login">
                      Login
                    </Link> */}
                    <Link
                      to="/facility/signup"
                      className="login-logout"
                      id="facility"
                    >
                      {HS}
                    </Link>
                    <Link
                      to="/clinician/signup"
                      className="login-logout"
                      id="clinician"
                    >
                      {AW}
                    </Link>
                  </DropdownMenu>
                </Dropdown>
              )}
            </Collapse>
          </Navbar>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
