import { api } from "api/Api";
import DatesLabel from "component/common/DatesLabel";
import ReceiptClinicianModal from "component/jobPosting/Modals/ReceiptClinician";
import RateClinicianModal from "component/modals/RateClinicianModal";
import UploadTimeCardModal from "component/modals/UplaodTimeCardModal";
import {
  ADD_UPDATE_TIMECARD_URL,
  CLINICIAN_REMOVE_APPLICATION,
  GET_CLINICIAN_JOB_LIST,
} from "constants/ApiUrls";
import {
  APP_LIMIT,
  CURSORPOINTER,
  NO_RATINGS,
  RESPONSE_OK,
} from "constants/AppConstants";
import Cookies from "js-cookie";
import { isEmpty } from "radash";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-dropdown-select";
import { Link } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { ReactComponent as SortIcon } from "../../assets/images/icons/arrows-up.svg";
import { ReactComponent as TableMsgIcon } from "../../assets/images/icons/chat.svg";
import { CrossIcon, DownArrow } from "../../assets/svg";
import CustomPagination from "../../component/common/customPagination";
import "../../component/userListing/userListing.scss";
import {
  confirmShiftsSortBy,
  shiftStatus,
} from "../../views/authentication/signUpClinician/HourlyConstant";
import DirectTimeSheetUpload from "./DirectTimeSheetUpload";
import AddressLabel from "component/common/AddressLabel";

function MyConfirmShifts({ user, values, currentActiveTab }) {
  const initFilters = {
    skip: 0,
    limit: APP_LIMIT,
    forFacility: 0,
    shiftStatus: "",
    paymentStatus: "",
  };

  const [rateModal, setRateModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({ ...initFilters });
  const [selectedRow, setSelectedRow] = useState({});
  const [selected, setSelected] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [receiptModal, setReceiptModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [deleteApplication, setDeleteApplication] = useState({});

  const role = Cookies.get("userRole");

  useEffect(() => {
    getData();
  }, [filters, trigger, currentActiveTab]);

  const toggleReceiptModal = (row) => {
    setReceiptModal(!receiptModal);
    if (!row || isEmpty(row)) {
      setSelectedRow({});
    } else if (row || !isEmpty(row)) {
      setSelectedRow({ ...row });
    }
  };

  const toggleModal = (row) => {
    if (modal) {
      setModal(false);
    } else {
      setModal({
        jobApplicationId: row?.jobId,
        clinicianId: row?.clinicianId,
      });
    }
  };

  const css = new Map();
  css.set("upcoming", "primary");
  css.set("current", "primary");
  css.set("completed", "secondary");

  const getData = (withLoader = true) => {
    withLoader && setLoader(true);
    api(GET_CLINICIAN_JOB_LIST, {}, null, {
      clinicianId: values?.id || user?.clinicianId?.id,
      ...filters,
    }).then((res) => {
      if (res.status === RESPONSE_OK) {
        let data = [...res.data.data];

        if (filters?.sortBy) {
          data = data?.sort((a, b) => {
            if (filters?.sortBy === "totalTime") {
              if (filters?.direction === "zToA") {
                return (
                  Number(b?.totalWorkedHours) - Number(a?.totalWorkedHours)
                );
              } else {
                return (
                  Number(a?.totalWorkedHours) - Number(b?.totalWorkedHours)
                );
              }
            } else if (filters?.sortBy === "totalAmount") {
              if (filters?.direction === "zToA") {
                return Number(b?.totalAmount) - Number(a?.totalAmount);
              } else {
                return Number(a?.totalAmount) - Number(b?.totalAmount);
              }
            }
          });
        }
        setData([...data]);

        setCount(res.data.count);
      }
      withLoader && setLoader(false);
    });
  };
  const handleApplicationRemove = () => {
    if (Object.keys(deleteApplication).length > 0) {
      api(
        CLINICIAN_REMOVE_APPLICATION,
        {
          jobApplicationId: deleteApplication?.jobApplicationId,
          clinicianId: deleteApplication?.clinicianId,
        },
        null,
        {}
      )
        .then((res) => {
          getData();
          toggleModal();
        })
        .catch((err) => {
          toggleModal();
        });
    }
  };
  const handleUpdate = (file, jobApplicationId = "") => {
    let reqData = {
      jobApplicationId: selectedRow.id || jobApplicationId,
      timeCardUrl: file || "",
      clinicianId: user.clinicianId?.id,
      facilityId: "",
    };

    api(ADD_UPDATE_TIMECARD_URL, { ...reqData }).then((res) => {
      if (res.status === RESPONSE_OK) {
        if (uploadModal) {
          uploadToggle();
        }
        setTrigger(!trigger);
      }
    });
  };

  const uploadToggle = () => setUploadModal(!uploadModal);

  const ratingToggle = () => setRateModal(!rateModal);

  const columns = [
    {
      name: "Shift #",
      width: role === "admin" ? "70px" : "60px",
      selector: (row) => row.serialNumber,
    },
    // {
    //   name: "Facility ID",
    //   width: "105px",
    //   selector: (row) =>
    //     role === "admin" || role === "facility"
    //       ? row.serialNumber
    //       : row.facilitySerialNumber,
    // },
    {
      name: "Facility",
      width: "100px",
      selector: (row) => (
        <>
          <Link
            to={
              role === "admin"
                ? `/admin/facilitydetails/${row.facilityId}`
                : `/clinician/jobprofile/${row?.jobId}`
            }
            target="_blank"
            id={"fullname" + row.id}
          >
            {row.facilityOfficeName}
          </Link>
          <UncontrolledTooltip
            placement="bottom"
            target={"fullname" + row.id}
            id="facility-name"
          >
            {row.facilityOfficeName}
          </UncontrolledTooltip>
        </>
      ),
    },
    {
      name: "Nickname",
      width: "100px",
      selector: (row) => row.jobAddressNickname || "-",
    },
    {
      name: "Location",
      width: "100px",
      selector: (row) => (
        <AddressLabel
          arr={[{
            jobAddress1: row.jobAddress1,
            jobAddress2: row.jobAddress2,
            state: row.state,
            zipCode: row.zipCode,
          }]}
        />
      ),

    },
    {
      name: "Shift",
      width: role === "admin" ? "160px" : "150px",
      selector: (row) => <DatesLabel arr={row.shiftSlots} />,
    },
    {
      name: "Shift Status",
      width: role === "admin" ? "150px" : "120px",
      // width: "120px",
      selector: (row) => (
        // <div
        //   className={`text-${
        //     ["current", "upcoming"].includes(row.shiftStatus)
        //       ? "primary"
        //       : "secondary"
        //   } text-capitalize`}
        // >
        <div className={`text-${css.get(row.shiftStatus)} text-capitalize`}>
          {row.shiftStatus || "-"}
        </div>
      ),
    },
    ...(role !== "admin"
      ? [
          {
            name: "Chat",
            width: "62px",
            selector: (row) => (
              <Link
                to="/chat-profile"
                state={{ receiverId: row.facilityUserId }}
              >
                <TableMsgIcon />
              </Link>
            ),
          },
        ]
      : []),
    ...(role !== "admin"
      ? [
          {
            name: "Time card",
            width: "100px",
            style: {
              justifyContent: "center",
            },
            selector: (row) => (
              // <>
              //   <>
              //     <Button id={"id" + row.id} className="table-dot" type="button">
              //       {" "}
              //       <Options />
              //     </Button>
              //     <UncontrolledPopover
              //       id="table-option"
              //       placement="bottom"
              //       target={"id" + row.id}
              //       trigger="legacy"
              //     >
              //       <Link
              //         onClick={() => {
              //           setSelectedRow(row);
              //           uploadToggle();
              //         }}
              //       >
              //         {row.timeCardUrlForClinician ? "View" : "Upload"} Time Card
              //       </Link>
              //     </UncontrolledPopover>
              //   </>
              // </>
              <>
                <DirectTimeSheetUpload
                  data={row}
                  handleUpdate={(file) => {
                    handleUpdate(file, row.id);
                  }}
                />
                {/* <TimesheetManage
            data={row}
            uploadToggle={uploadToggle}
            setSelectedRow={setSelectedRow}
            // ViewOnClick={(e) => navigate(`/users/edit/${row?.id}`)}
            // AddOnClick={() => {
            //   toggleDeleteModal({
            //     id: row?.id,
            //     name: row?.name,
            //     email: row?.email,
            //   });

            // handleDeleteUser(row?.id);
          /> */}
                {/* <>
            <Button id={"id" + row.id} className="table-dot" type="button">
              {" "}
              <Options />
            </Button>
            <UncontrolledPopover
              id="table-option"
              placement="bottom"
              target={"id" + row.id}
              trigger="legacy"
            >
              <div className="timeCard-facility">
                <div className="upload-time">
                  <Link
                    onClick={() => {
                      setSelectedRow(row);
                      uploadToggle();
                    }}
                  >
                    {" "}
                    {row.timeCardUrlForClinician ? "View" : "Upload"} Time Card
                  </Link>
                </div>
                <div className="timesheet-border">
                  <a href={TIMESHEET_URL}> Download Timesheet</a>
                </div>
              </div>
            </UncontrolledPopover>
          </> */}
              </>
            ),
          },
        ]
      : []),
    {
      name: "Time Worked (hrs)",
      width: role === "admin" ? "130px" : "85px",
      // width: "100px",
      selector: (row) => Number(row.totalWorkedHours) || 0,
    },
    {
      name: "Hourly Rate ($)",
      width: role === "admin" ? "150px" : "85px",
      // width: "105px",
      selector: (row) => Number(row.hourlyRate) || 0,
    },
    // {
    //   name: "Payment Status",
    //   width: "100px",
    //   selector: (row) => (
    //     <div className="text-primary">
    //       {PAYMENT_STATUS_LABELS.get(row.paymentStatus)}
    //     </div>
    //   ),
    // },
    // {
    //   name: "Receipt",
    //   width: "79px",
    //   selector: (row) => {
    //     return (
    //       <>
    //         {row?.invoiceForClinician?.length ? (
    //           <Link
    //             className="text-primary"
    //             onClick={() => toggleReceiptModal(row)}
    //             style={CURSORPOINTER}
    //           >
    //             View
    //           </Link>
    //         ) : (
    //           <>-</>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      name: "Rating",
      width: role === "admin" ? "130px" : "103px",
      // width: "103px",
      selector: (row) => {
        const { shiftSlots } = row;

        const shifts = shiftSlots?.sort((a, b) => a?.sortOrder - b?.sortOrder);
        if (shifts?.length > 0) {
          const lastShiftEndDate = new Date(
            shifts[shifts?.length - 1]?.endDate
          );
          const nextWeek = new Date(
            lastShiftEndDate.getFullYear(),
            lastShiftEndDate.getMonth(),
            lastShiftEndDate.getDate() + 7
          );
          const today = new Date();

          return (
            <>
              {row.shiftStatus === "completed" &&
              nextWeek > today &&
              Number(row?.ratingForFacility) < 1 ? (
                <div className="text-primary">
                  <Link
                    className="text-primary"
                    onClick={() => {
                      ratingToggle();
                      setSelected(row);
                    }}
                    style={CURSORPOINTER}
                  >
                    Rate Now
                  </Link>
                </div>
              ) : (
                <>
                  {row.shiftStatus !== "completed"
                    ? "-"
                    : `${
                        row?.ratingForFacility
                          ? row?.ratingForFacility + "/ 5"
                          : NO_RATINGS
                      }`}
                </>
              )}
            </>
          );
        } else {
          return <>-</>;
        }
      },
    },
    {
      name: "",
      width: role === "admin" ? "120px" : "101px",
      // width: "101px",
      selector: (row) => (
        <div
          className="text-primary"
          style={{ display: "flex  align-items-center", width: "120px" }}
        >
          <Link
            style={{ marginRight: "15px" }}
            to={
              role === "admin"
                ? `/admin/jobdetail/${row.jobId}`
                : `/clinician/jobprofile/${row.jobId}`
            }
            target="_blank"
          >
            Job details
          </Link>
          {row.jobStatus === "posted" && (
            <button
              id={"rej" + row.jobId}
              className="pt-btn-icon-small btn-danger-small"
              // style={{ width: "10px !important", height: "10px", lineHeight: "10px" }}
              onClick={() => {
                toggleModal();
                setDeleteApplication({
                  jobApplicationId: row.id,
                  clinicianId: row.clinicianId,
                });
              }}
            >
              <CrossIcon />
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="facility-payment-detail confirm-shift-table confirm-clinician">
        <div className="header" style={{ display: "flex" }}>
          <div className="user-sorting usertype" style={{ display: "flex" }}>
            <label htmlFor="type">Shift status</label>
            <div className="sort">
              <Select
                placeholder={shiftStatus[0]?.label}
                onChange={(val) => {
                  setFilters({
                    ...filters,
                    skip: 0,
                    limit: APP_LIMIT,
                    shiftStatus: val && val?.length ? val[0].value : null,
                  });
                }}
                options={shiftStatus}
                id="type"
              />
            </div>
          </div>
          {/* <div className="user-sorting usertype">
            <label htmlFor="type">Payment status</label>
            <div className="sort">
              <Select
                placeholder={paymentStatusFac[0]?.label}
                onChange={(val) => {
                  setFilters({
                    ...filters,
                    skip: 0,
                    limit: APP_LIMIT,
                    paymentStatus: val && val.length ? val[0].value : "",
                  });
                }}
                options={paymentStatusFac}
                id="type"
              />
            </div>
          </div> */}
          <div className="hourly me-0 ms-md-auto">
            <div className="hourly-select">
              <div
                className="user-sorting usertype"
                style={{ display: "flex" }}
              >
                <label htmlFor="type">Sort by</label>
                <div className="sort">
                  <Select
                    placeholder={confirmShiftsSortBy[0]?.label}
                    onChange={(val) => {
                      setFilters({
                        ...filters,
                        skip: 0,
                        limit: APP_LIMIT,
                        sortBy: val && val.length ? val[0].value : "",
                      });
                    }}
                    options={confirmShiftsSortBy}
                    id="type"
                  />
                </div>
                {filters?.sortBy && (
                  <div className="header">
                    <div className="user-sorting">
                      <div className="usertype">
                        <div className="hourly-btn mt-3">
                          <span
                            className={`uparrow ${
                              (filters.direction === "aToZ" ||
                                !filters?.direction) &&
                              "active"
                            }`}
                            onClick={() =>
                              setFilters({
                                ...filters,
                                direction:
                                  filters.direction !== "aToZ" ? "aToZ" : "",
                              })
                            }
                          >
                            <DownArrow />
                          </span>
                          <span
                            className={`down ${
                              filters.direction === "zToA" && "active"
                            }`}
                            onClick={() =>
                              setFilters({
                                ...filters,
                                direction:
                                  filters.direction !== "zToA" ? "zToA" : "",
                              })
                            }
                          >
                            <DownArrow />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          centered
          isOpen={modal}
          toggle={toggleModal}
          // {...args}
          className="applicant-modal"
        >
          <ModalHeader toggle={toggleModal}>Remove job application</ModalHeader>
          <ModalBody>
            <label className="font-12 mb-24 d-block text-center">
              Are you sure you want to remove this job application?
            </label>
            <div className="modal-footer">
              <button
                className="pt-btn btn-gray pt-btn-small"
                onClick={() => toggleModal()}
              >
                Cancel
              </button>
              <button
                className="pt-btn btn-primary pt-btn-small"
                onClick={() => handleApplicationRemove()}
              >
                Remove
              </button>
            </div>
          </ModalBody>
        </Modal>
        <DataTable
          columns={columns}
          data={data}
          progressPending={loader}
          progressComponent={<Spinner color="primary" />}
          sortIcon={<SortIcon />}
          noDataComponent= {<p className="text-center no-records"> No jobs found </p>}
        />
        <CustomPagination
          count={count}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <UploadTimeCardModal
        modal={uploadModal}
        toggle={uploadToggle}
        handleSave={(file) => handleUpdate(file)}
        serverTimeCard={selectedRow?.timeCardUrlForClinician}
      /> 
      {/* <EnterTimeSheetModal
        modal={enterSheetModal}
        toggle={() => {
          handleShiftslots({});
          setSelected({});
        }}
        getData={getData}
        selectedRow={selectedRow}
      /> */}
      {/* <DeleteTimeSheetModal
        modal={deleteSheetModal}
        toggle={() => deleteSheetToggle({})}
        data={timeSheetId}
        getData={getData}
      /> */}
      <ReceiptClinicianModal
        data={selectedRow}
        isOpen={receiptModal}
        toggle={() => toggleReceiptModal({})}
        loading={loader}
        setLoading={setLoader}
        totalAmount={selectedRow?.totalAmount}
      />
      {rateModal && (
        <RateClinicianModal
          modal={rateModal}
          toggle={() => {
            setRateModal(!rateModal);
            getData();
          }}
          data={selected}
          reviewFor={selected?.facilityUserId}
          reviewBy={selected?.clinicianUserId}
          // for={}
        />
      )}
    </>
  );
}

export default MyConfirmShifts;
