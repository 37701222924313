import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { useState, useEffect } from 'react';
import { state } from 'views/authentication/signUpClinician/HourlyConstant';
import { LocationPin } from 'assets/svg';
import FormError from './FormError';
import { AddressRules } from 'constants/Rules';

const PlacesInput = ({
  setValue,
  register,
  error,
  value,
  addressLine,
  addressState,
  addressZipcode,
  addressCity,
  placeholder,
}) => {
  const [address, setAddress] = useState(value || '');

  useEffect(() => {
    setAddress(value || '');
  }, [value]);

  const handleAddressChange = (address) => {
    setAddress(address);
    setValue(addressLine, address);
  };

  const handleAddressSelect = async (selectedAddress) => {
    setAddress(selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);
      const addressComponents = results[0].address_components;

      const cityComponent = addressComponents.find((component) =>
        component.types.includes('locality'),
      );
      const city = cityComponent ? cityComponent.long_name : '';

      const stateComponent = addressComponents.find((component) =>
        component.types.includes('administrative_area_level_1'),
      );
      const stateCode = stateComponent ? stateComponent.short_name : null;
      const selectedState = state.find((s) => s.stateCode === stateCode);

      const zipComponent = addressComponents.find((component) =>
        component.types.includes('postal_code'),
      );
      const zipCode = zipComponent ? zipComponent.long_name : '';

      addressLine && setValue(addressLine, selectedAddress);
      addressZipcode && setValue(addressZipcode, zipCode);
      addressState && setValue(addressState, selectedState?.label || '');
      addressCity && setValue(addressCity, city);
    } catch (error) {
      console.error('Error:', error);
      clearAddressState();
    }
  };

  const clearAddressState = () => {
    addressLine && setValue(addressLine, '');
    addressZipcode && setValue(addressZipcode, '');
    addressState && setValue(addressState, '');
    addressCity && setValue(addressCity, '');
  };

  useEffect(() => {
    if (!address) {
      clearAddressState();
    }
  }, [address]);

  return (
    <div className="form-group nick-name">
      <PlacesAutocomplete
        value={address}
        onChange={handleAddressChange}
        onSelect={handleAddressSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="office-address">
            <input
              {...register(addressLine, AddressRules)}
              {...getInputProps({
                placeholder: placeholder,
                className: error?.message
                  ? 'form-control required autocomplete'
                  : 'form-control',
              })}
              type='text'
            />
            <div className="autocomplete-dropdown-container">
              {loading && (
                <div className="loading-container">
                  <div className="loading-spinner"></div>
                  <span style={{ fontSize: '12px' }}>Loading...</span>
                </div>
              )}
              {suggestions.map((suggestion) => {
                const className = `suggestion-item ${
                  suggestion.active ? 'suggestion-item--active' : ''
                }`;
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                    key={suggestion.placeId}
                    style={{
                      backgroundColor: suggestion.active
                        ? '#e6f6f4'
                        : '#FFFFFF',
                      cursor: 'pointer',
                      padding: '10px',
                      fontSize: '12px',
                      border: '1px solid #E0E0E0',
                    }}>
                    <span className="mr-4">
                      <LocationPin />
                    </span>
                    <span className="suggestion-text">
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {error?.message && (
        <FormError
          msg={error?.message}
          className="autocomplete"
        />
      )}
    </div>
  );
};

export default PlacesInput;
