import { useEffect, useState } from "react";
import { LocationPin } from "assets/svg";
import FormCleave from "component/common/FormCleave";
import FormDatePicker from "component/common/FormDatePicker";
import FormInput from "component/common/FormInput";
import FormRadio from "component/common/FormRadio";
import FormSelect from "component/common/FormSelect";
import {
  AddressRules,
  CommentRules,
  DOBRules,
  FirstNameRules,
  GenderRules,
  LanguageRules,
  LastNameRules,
  messagePhoneRules,
  NameRules,
  phoneRules,
  ReferenceByRules,
  StateRules,
  ZipCodeRules,
} from "constants/Rules";
import { useNavigate, useLocation } from "react-router-dom";
import FormCheckbox from "component/common/FormCheckbox";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import FooterButtons from "../signUpFacility/FooterButtons";
import { gender, language, refBy, state } from "./HourlyConstant";
import ReactGA from "react-ga4";
import FormError from "component/common/FormError";

export default function SignUpClinicianSecond({
  register,
  errors,
  setCurStep,
  curStep,
  trigger,
  control,
  watch,
  resetField,
  unregister,
  formData,
  callAPI,
  loader,
  user,
  setValue,
  getValues,
}) {
  const location = useLocation();
  const PageTitle = document?.title;
  ReactGA.send({
    hitType: "pageview",
    page: location?.pathname,
    title: `${PageTitle} - Step 2`,
  });
  
  let navigate = useNavigate();
  const referenceBy = watch("referenceBy");
  const hearOtherOptions = ["Other", "Referral"];
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [addressError, setAddressError] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  useEffect(() => {
    if (!hearOtherOptions.includes(referenceBy)) {
      resetField("referenceByOther");
      unregister("referenceByOther");
    }
  }, [referenceBy]);

  const handleNext = async () => {
    if (addressError) {
      return;
    }
    if (await trigger()) {
      try {
        await callAPI({
          ...formData,
          homeAddress1: address,
          homeAddressCity: city,
          signupStageCount:
            user?.clinicianId?.signupStageCount > 1
              ? user?.clinicianId?.signupStageCount
              : 1,
        });
        navigate("/clinician/signup?step=2");
        setCurStep(curStep + 1);
      } catch (error) {
        console.log(error, "error");
      }
    }
    ReactGA.event({
      category: 'Signup',
      action: 'Clicked Save Button',
      label: 'Saved Clinician Signup Form',
    });
  };
  
  const handleAddressChange = (address) => {
    setAddress(address);
    if (!address) {
      clearAddressState();
      setAddressError("Address is required");
    } else {
      setAddressError("");
    }
  };

  useEffect(() => {
    const savedAddress = getValues("homeAddress1");
    const savedZipCode = getValues("homeAddressZipCode");
    if (savedAddress) {
      setAddress(savedAddress);
      setValue("homeAddress1", savedAddress);
    }
    if (savedZipCode) {
      setValue("homeAddressZipCode", savedZipCode);
    }
  }, [setValue, getValues]);

  const handleAddressSelect = async (address) => {
    setAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;
      const cityComponent = addressComponents.find((component) =>
        component.types.includes("locality")
      );
      const city = cityComponent ? cityComponent.long_name : "";
      setCity(city);

      const stateComponent = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const stateCode = stateComponent ? stateComponent.short_name : null;
      const selectedState = state.find((s) => s.stateCode === stateCode);

      const zipComponent = addressComponents.find((component) =>
        component.types.includes("postal_code")
      );
      const zipCode = zipComponent ? zipComponent.long_name : "";

      setValue("homeAddress1", address);
      setValue("homeAddressZipCode", zipCode);
      setValue("homeAddressState", selectedState?.label);
      const latLng = await getLatLng(results[0]);
      setCoordinates(latLng);
    } catch (error) {
      console.error("Error:", error);
      clearAddressState();
    }
  };

  const clearAddressState = () => {
    setValue("homeAddressState", "");
    setValue("homeAddressZipCode", "");
    setValue("homeAddress1", "");
    setCoordinates({ lat: null, lng: null });
  };
  
  return (
    <>
      <h3>General information</h3>
      <FormInput
        inputDivClassName={"signup-input"}
        name={"firstName"}
        id={"firstName"}
        type={"text"}
        label={"First Name"}
        register={register}
        rules={FirstNameRules}
        errors={errors}
      />
      <FormInput
        inputDivClassName={"signup-input"}
        name={"lastName"}
        id={"lastName"}
        type={"text"}
        label={"Last Name"}
        register={register}
        rules={LastNameRules}
        errors={errors}
      />
      <FormSelect
        options={gender}
        placeholder="Select"
        control={control}
        name={"gender"}
        errors={errors}
        optionValue="value"
        optionLabel="label"
        rules={GenderRules}
        label="Gender:"
        trigger={trigger}
      />
      <FormDatePicker
        label={"Date of birth:"}
        name={"dateOfBirth"}
        id={"dateOfBirth"}
        errors={errors}
        rules={DOBRules}
        control={control}
        maxDate={new Date()}
        trigger={trigger}
        inputDivClassName="dateofbirth"
      />
      <FormCleave
        trigger={trigger}
        control={control}
        name={"phone"}
        id={"phone"}
        type={"number"}
        label={"Phone number:"}
        rules={phoneRules}
        errors={errors}
        checkBox={
          <div className="d-flex gap-1 align-items-center mt-1">
            <FormCheckbox
              control={control}
              divClassName="i-agree mt-1"
              className="form-check-input"
              name="allowSMSNotifications"
              options={[{ label: "", value: "allowSMSNotifications" }]}
              defaultChecked={true}
              register={register}
              errors={errors}
            ></FormCheckbox>
            <div
              style={{ fontSize: "12px", color: "#80798b", marginTop: "12px" }}
            >
              We send some notifications via text (shift confirmations, chat
              messages, etc)
            </div>
          </div>
        }
        // extraText={
        //   <p>
        //     We send some notifications via text (shift confirmations, chat
        //     messages, etc ).
        //   </p>
        // }
      />
      <FormSelect
        options={language}
        placeholder="Select"
        control={control}
        isClearable={true}
        name={"knownLanuages"}
        errors={errors}
        optionValue="value"
        optionLabel="label"
        rules={LanguageRules}
        multiple={true}
        label="Languages spoken:"
        className="lang"
        trigger={trigger}
      />
      {/* <FormSelect
        options={clinician}
        placeholder="Select"
        control={control}
        name={"roles"}
        errors={errors}
        optionValue="value"
        optionLabel="label"
        rules={ClinicianTypeRules}
        label="Clinician type:"
        trigger={trigger}
      /> */}
      <hr></hr>
      <h3>Emergency contact</h3>
      <FormInput
        inputDivClassName={"signup-input"}
        name={"emrgencyContactName"}
        id={"emrgencyContactName"}
        type={"text"}
        label={"Name:"}
        register={register}
        rules={NameRules}
        errors={errors}
      />

      <FormCleave
        trigger={trigger}
        control={control}
        name={"emrgencyContactPhone"}
        id={"emrgencyContactPhone"}
        type={"number"}
        label={"Phone number:"}
        rules={phoneRules}
        errors={errors}
      />
      <hr></hr>
      <h3>Home address</h3>
      <PlacesAutocomplete
        value={address}
        onChange={handleAddressChange}
        onSelect={handleAddressSelect}
        rules={AddressRules}
        name={"homeAddress1"}
        id={"homeAddress1"}
        errors={errors}
        control={control}
        trigger={trigger}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="form-group office-address">
            <input
              {...getInputProps({
                placeholder: "Address line 1",
                className: "form-control",
              })}
              type="text"
            />
            <div className="autocomplete-dropdown-container">
              {loading && (
                <div className="loading-container">
                  <div className="loading-spinner"></div>
                  <span style={{ fontSize: "12px" }}>Loading...</span>
                </div>
              )}
              {suggestions.map((suggestion) => {
                const className = `suggestion-item ${
                  suggestion.active ? "suggestion-item--active" : ""
                }`;
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                    key={suggestion.placeId}
                    style={{
                      backgroundColor: suggestion.active
                        ? "#e6f6f4"
                        : "#FFFFFF",
                      cursor: "pointer",
                      padding: "10px",
                      fontSize: "12px",
                      border: "1px solid #E0E0E0",
                    }}
                  >
                    <span className="mr-4">
                      <LocationPin />
                    </span>
                    <span className="suggestion-text">
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {addressError && (
                <FormError
                  msg={addressError}
                  className="autocomplete"
                />
              )}
      <FormInput
        name={"homeAddress2"}
        id={"homeAddress2"}
        type={"text"}
        register={register}
        rules={{}}
        errors={errors}
        placeholder="Address line 2"
        divClassName="form-group office-address"
      />

      <FormSelect
        options={state}
        placeholder="Select"
        control={control}
        name={"homeAddressState"}
        errors={errors}
        optionValue="value"
        optionLabel="label"
        rules={StateRules}
        label="State:"
        trigger={trigger}
      />
      <FormInput
        name={"homeAddressZipCode"}
        id={"homeAddressZipCode"}
        type={"number"}
        label={"Zip code:"}
        register={register}
        rules={ZipCodeRules}
        errors={errors}
      />

      <hr></hr>
      <div className="form-group hear-about">
        <p>How did you hear about Purple PRN?</p>
        <FormRadio
          divClassName="pt-radio"
          name={"referenceBy"}
          options={refBy}
          register={register}
          rules={ReferenceByRules}
          errors={errors}
          className="pt-radio"
        />
      </div>

      <FormInput
        divClassName="form-group office-address heartext"
        name={"referenceByOther"}
        id={"referenceByOther"}
        type={"textarea"}
        label={""}
        register={register}
        rules={hearOtherOptions.includes(referenceBy) ? CommentRules : {}}
        disabled={!hearOtherOptions.includes(referenceBy)}
        errors={errors}
        placeholder="If someone referred you, write their name here. You will both receive a reward after completing your first shift!"
      />

      <FooterButtons
        // leftLabel={"Prev"}
        rightLabel={"Save & Next"}
        setCurStep={setCurStep}
        curStep={curStep}
        loader={loader}
        onRightClick={() => handleNext()}
      />
    </>
  );
}
