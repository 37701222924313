import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "component/accountSettings/accountSettings.scss";
import classnames from "classnames";
import AdminFacilityGeneralInformation from "views/admin/accountSettings/adminFacilitySettings/facilityGeneralSettings";

function AdminFacilityAccountSetting() {
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState("1");

  // Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  return (
    <>
      <Helmet>
        <title>Purple PRN - Account Settings</title>
      </Helmet>
      <div className="account-settings-main">
        <div className="custom-container">
          <h2>Facility Account</h2>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === "1",
                })}
                onClick={() => {
                  toggle("1");
                }}
              >
                General information
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              <AdminFacilityGeneralInformation />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
}

export default AdminFacilityAccountSetting;
