/* eslint-disable brace-style */
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// "firebase": "^9.15.0",
export const firebaseConfig = {
  apiKey: "AIzaSyBbDF0YYhORgNi1WfnD6w0UPPv2jGaxRPM",
  authDomain: "purplept-dev.firebaseapp.com",
  projectId: "purplept-dev",
  storageBucket: "purplept-dev.appspot.com",
  messagingSenderId: "992699422584",
  appId: "1:992699422584:web:6b31dfa2a01a7a6a7a1a8d",
  measurementId: "G-EKE9Q07TJW",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const messaging = getMessaging();
const publicKey = process?.env?.REACT_APP_VAPID_KEY;

const getBrowsername = () => {
  let nAgt = navigator.userAgent;
  let browserName = '';
  let nameOffset, verOffset;

  // In Opera, the true version is after "OPR" or after "Version"
  if ((verOffset = nAgt.indexOf("OPR")) !== -1) {
    browserName = "Opera";
  }
  // In MS Edge, the true version is after "Edg" in userAgent
  else if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
    browserName = "Edge";
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
  }
  // In Chrome, the true version is after "Chrome" 
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
  }
  // In Safari, the true version is after "Safari" or after "Version" 
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
  }
  // In Firefox, the true version is after "Firefox" 
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
  }
  // In most other browsers, "name/version" is at the end of userAgent 
  else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }

  return browserName;
}
/**
 * This function registers a service worker and returns a token for Firebase messaging.
 * @returns The `requestForToken` function is returning a promise that resolves to a Firebase Cloud
 * Messaging (FCM) token. The function first registers the service worker located at
 * `/purplept/webapp/firebase-messaging-sw.js`, and then calls the `getToken` function with the
 * `messaging` object, a `vapidKey` and the `serviceWorkerRegistration` object. If the `getToken`
 */
export const requestForToken = async () => {
  let browserName = getBrowsername();
  if (browserName.toLowerCase() === 'edge') {
    return null
  }
  const swPath = "/purplept/webapp/firebase-messaging-sw.js";
  let registration = await navigator.serviceWorker.register(swPath);
  return await getToken(messaging, {
    vapidKey: publicKey,
    serviceWorkerRegistration: registration,
  }).catch((err) => {
    console.log(err);
    return "";
  });
};