/*eslint-disable */
import axios from "axios";
import Cookies from "js-cookie";
import { isEmpty } from "radash";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MAX_FIVE_MB_SERVER,
  TOAST_AUTO_CLOSE,
  TOKEN_NAME,
} from "../constants/AppConstants";
import queryString from "query-string";
import { FILE_MAX_LIMIT } from "constants/messages";
const URLS = new Map();
// ?reviewFor=b5c21637-3092-4d29-8d08-587c75fc8e3f
URLS.set("USER", process.env.REACT_APP_API_URL_USER);
URLS.set("MAP", process.env.REACT_APP_API_URL_MAP);
/**
 *
 * @param {Object} endpoint (One Obj get from ApiUrls)
 * @param {Object} data (data that we send in request... Generally used in POST, PUT methods)
 * @param {String} id (append data to URL...usually used tn PUT methods)
 * @param {Object} params (generates query string from the object)
 * @returns
 */
export const api = async (endpoint, data, id = null, params = null) => {
  const {
    method,
    isMultipart,
    url,
    showToast,
    module,
    ignoreError,
    responseType,
  } = endpoint;
  let res = null;
  let token = Cookies.get([TOKEN_NAME]);
  try {
    let obj = {
      data,
      method: method,
      headers: {
        "Content-Type": isMultipart
          ? `multipart/form-data boundary=${data._boundary}`
          : "application/json",
      },
      responseType: responseType || "",
      url:
        URLS.get(module) +
        url +
        (id ? id : "") +
        (params ? `?${queryString.stringify(params)}` : ""),
    };
    if (!responseType) {
      delete obj?.responseType;
    }
    if (method === "GET" && data && typeof data === "string") {
      obj.url += data;
    }
    if (method === "POST") {
      if (!data) data = {};
    }

    if (token) obj.headers["x-auth"] = token;

    res = await axios(obj);
  } catch (err) {
    res = err.response;

    const { message, status, error } = res?.data;
    // console.log(status);
    if (status === 401) {
      Cookies.remove("purplePTAuthToken");
      window.location.replace(`${process.env.PUBLIC_URL}/login`);
    }
    if (status === 403) {
      Cookies.remove("purplePTAuthToken");
      window.location.replace(`${process.env.PUBLIC_URL}/login`);
    }
    // if (status === 403) {
    //   window.location.replace(`${process.env.PUBLIC_URL}/403`);
    // }

    let displayMsg =
      error && typeof error === "object" && !isEmpty(error)
        ? typeof error[Object.keys(error)[0]] === "string"
          ? error[Object.keys(error)[0]]
          : error[Object.keys(error)[0]][0]
        : error && typeof error === "string"
        ? error
        : message
        ? message
        : "Something went wrong";

    try {
      !ignoreError &&
        toast.error(
          displayMsg === MAX_FIVE_MB_SERVER ? FILE_MAX_LIMIT(10) : displayMsg,
          {
            transition: Slide,
            autoClose: TOAST_AUTO_CLOSE,
          }
        );

      return {
        data: {
          error: true,
          status: status,
          message:
            displayMsg === MAX_FIVE_MB_SERVER ? FILE_MAX_LIMIT(5) : displayMsg,
        },
      };
    } catch (error) {
      return {
        data: {
          error: true,
          status: status,
          message:
            displayMsg === MAX_FIVE_MB_SERVER ? FILE_MAX_LIMIT(5) : displayMsg,
        },
      };
    }
  }

  if (res && res.data && res.data.status === 200 && showToast)
    toast.success(res.data.message, {
      autoClose: TOAST_AUTO_CLOSE,
    });

  return res;
};
